'use client';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import Link from 'next/link';

export default function NotFoundPage() {
  return (
    <div className="mx-3 flex justify-center">
      <Card className="flex min-h-48 w-4/5 flex-col items-center border-0 bg-backdrop shadow-none">
        <CardHeader>
          <CardTitle className="center text-2xl">404 Page Not Found</CardTitle>
        </CardHeader>
        <CardContent className="flex items-center justify-center">
          <Link href="/">
            <Button>Go Back Home</Button>
          </Link>
        </CardContent>
      </Card>
    </div>
  );
}
